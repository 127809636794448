import { axios } from 'shared/axiosClient';
import { Auth0User, SalesforceCreateUser } from 'types/global';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useMutation } from '@tanstack/react-query';
import { ClientProvisionResponse } from '../models/AccountData';
import { DefaultRolesRes, SaveDefaultRoleRes, SaveDefaultRolesArr } from '../sb/models/Role';

export const getAccountUsers = async (account_id: string | number | undefined) => {
  try {
    const { data } = await axios.get(`/secure/account/${account_id}/users`);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Retrieve users for the company account of the logged-in user
 */
export const getMyCompanyUsers = async () => {
  try {
    const { data } = await axios.get(`/secure/account/users`);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Retrieve users for the company account of the logged-in user
 */
export const getSelectedClientUsers = async (profile_id?: number) => {
  try {
    const { data } = await axios.get(`/secure/client/users`, {
      params: { profile_id }
    });
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Update user
 *
 * @param account_id
 * @param user_id
 * @param vals
 */
export const updateUser = async (account_id: string, user_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/account/${account_id}/user/${user_id}`;
    const { data } = await axios.patch(url, vals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Updates the details of a federated user associated with a specific account.
 *
 * This function communicates with the server using a PATCH request to update
 * an existing federated user's information. It requires the account ID, the user ID,
 * and the new values to be updated as input parameters.
 *
 * @param {string} account_id - The unique identifier for the federated account.
 * @param {string} user_id - The unique identifier for the user within the federated account.
 * @param {Auth0User} vals - An object containing the user properties to be updated.
 * @throws Handles errors through AxiosErrorHandler in case of a request failure.
 */
export const updateFederatedUser = async (account_id: string, user_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/federated-account/${account_id}/user/${user_id}`;
    const { data } = await axios.patch(url, vals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Asynchronously updates a federated client user's information.
 *
 * @param {string} user_id - The unique identifier of the user to be updated.
 * @param {Auth0User} vals - An object containing the updated user details.
 * @throws Will invoke the error handler in case of a request failure.
 */
export const updateFederatedClientUser = async (user_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/federated-account/user/${user_id}`;
    const { data } = await axios.patch(url, vals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * As an admin, update my company's user
 *
 * @param user_id
 * @param vals
 */
export const updateMyUser = async (user_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/user/${user_id}`;
    const { data } = await axios.patch(url, vals);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Updates a client user with specified values.
 *
 * @param {string} user_id - The ID of the user to be updated.
 * @param {Auth0User} vals - The values to be updated for the user.
 * @returns {Promise<any>} A Promise that resolves to the updated user data, or rejects with an error.
 */
export const updateClientUser = async (user_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/account/user/${user_id}`;
    const { data } = await axios.patch(url, vals);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Function to insert a new client user into the system.
 *
 * @param {Auth0User} vals - The client user object to be inserted.
 * @returns {Promise} - A Promise that resolves with the inserted user data.
 */
export const insertClientUser = async (vals: Auth0User) => {
  try {
    const url = `/secure/user`;
    const { data } = await axios.post(url, vals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Insert user
 *
 * @param account_id
 * @param vals
 */
export const insertUser = async (account_id: string, vals: Auth0User) => {
  try {
    const url = `/secure/account/${account_id}/user`;
    const { data } = await axios.post(url, vals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Insert a user into my company
 *
 * @param vals
 */
export const insertMyUser = async (vals: Auth0User) => {
  try {
    const url = `/secure/user`;
    const { data } = await axios.post(url, vals);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Insert Salesforce contact and user record
 *
 * @param vals
 */
export const insertSalesforceUser = async (vals: SalesforceCreateUser) => {
  try {
    const url = `/secure/sf/contact`;
    const { data } = await axios.post(url, vals);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Select client access roles
 */
export const selectUserRoles = async (salesforce_id?: string | number | undefined) => {
  try {
    const url = `/secure/user/roles/${salesforce_id}`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update client contract digital entitlements
 *
 * @param sf_account_id
 * @param sf_contract_id
 * @param entitlements
 * @param start_date
 * @param end_date
 */
export const updateClientContractEntitlements = async (
  sf_account_id: string,
  sf_contract_id: string,
  entitlements: number,
  start_date: string,
  end_date: string
) => {
  try {
    const url = `/secure/account/${sf_account_id}/contract/${sf_contract_id}/entitlements`;
    const { data } = await axios.patch(url, { entitlements, start_date, end_date });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Select users with NPI contract entitlements
 *
 * @param sf_account_id
 */
export const selectAccountAllocatedSeats = async (sf_account_id: string) => {
  try {
    const url = `/secure/account/${sf_account_id}/current_contract/allocated_seats`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Select list of a client's encrypted email domains
 *
 * @param sf_account_id
 */
export const selectClientEmailDomains = async (sf_account_id: string) => {
  try {
    const url = `/secure/account/${sf_account_id}/email_domains`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    return e;
  }
};

/**
 * Update Client Email Domains
 *
 * @param sf_account_id
 * @param email_domains
 */
export const updateClientEmailDomains = async (sf_account_id: string, email_domains: Array<string>) => {
  try {
    const url = `/secure/account/${sf_account_id}/email_domains`;
    const { data } = await axios.patch(url, email_domains);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Create Auth0 Organization Record
 *
 * @param sf_account_id
 * @param name
 */
export const createAuth0Organization = async (
  sf_account_id: string,
  name?: string
): Promise<ClientProvisionResponse | undefined> => {
  try {
    const url = `/secure/account/${sf_account_id}/provision`;
    const { data } = await axios.post(url, { name });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch user from Auth0
 *
 * @param auth0_user_id
 */
export const fetchAuth0User = async (auth0_user_id: string) => {
  try {
    const url = `/auth0/user/${auth0_user_id}`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Delete user from the DB and Auth0
 *
 * @param auth0_user_id
 */
export const deleteUser = async (auth0_user_id: string) => {
  try {
    const url = `/secure/user/${auth0_user_id}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Select available client entitlements by latest contract and the auth0 ids of users
 * allocated to those entitlements
 */
export const fetchClientEntitlements = async () => {
  try {
    const url = `/secure/client/entitlements`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Initiate user password reset ticket workflow
 * @param email
 */
export const sendUserPasswordReset = async (email: string) => {
  try {
    const url = `/secure/send_password_reset`;
    const payload: Record<string, any> = { email };
    const { data } = await axios.post(url, payload);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Send password reset email to selected email address
 *
 * @param email
 */
export const sendPasswordReset = async (email?: string) => {
  try {
    const url = `/secure/me/change_password`;
    const payload: Record<string, any> = {};
    if (email) {
      payload.email = email;
    }
    const { data } = await axios.post(url, payload);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const useResetPassword = (config = {}) =>
  useMutation({
    mutationFn: async (data: { email?: string }) => {
      const { email } = data;
      return await sendPasswordReset(email);
    },
    ...config
  });

/**
 * Fetches the default roles associated with a specific profile.
 *
 * @param {string|number} profile_id - The profile ID for which default roles are to be fetched.
 * @returns {Promise<any>} - A Promise that resolves with the data containing default roles.
 */
export const fetchDefaultRoles = async (profile_id?: string | number): Promise<DefaultRolesRes | undefined> => {
  try {
    const { data } = await axios.get(`/secure/default_roles`, {
      params: { profile_id }
    });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Asynchronously fetches saved default roles for a specified profile.
 * If no profile_id provided, it fetches the default roles for the current profile.
 *
 * @param {string|number} profile_id - The ID of the profile to fetch default roles for.
 * @returns {Promise} - A Promise that resolves with the fetched default roles data.
 * @throws {Error} - An error is thrown if the fetching process fails.
 */
export const fetchSavedDefaultRoles = async (profile_id?: string | number): Promise<DefaultRolesRes | undefined> => {
  try {
    const { data } = await axios.get(`/secure/client/default_roles`, {
      params: { profile_id }
    });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const saveDefaultRoles = async (
  vals: SaveDefaultRolesArr,
  profile_id?: string | number
): Promise<SaveDefaultRoleRes | undefined> => {
  try {
    const url = `/secure/client/update/default_roles`;
    const { data } = await axios.patch(url, vals, {
      params: { profile_id }
    });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
