import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Button, Card, Checkbox, Col, Input, message, Row } from 'antd';

import {
  contractEntitlementsState,
  selectedAuth0UserState,
  userContractEntitlementsState,
  usersState,
  loadingUsersState,
  isAccountFederatedSignonState
} from 'recoil/atoms';
import { ACLCheck } from 'shared/Helpers';
import { Auth0User, Role } from 'types/global';
import { deleteUser, sendUserPasswordReset } from 'api/admin';
import { UserManagementTable } from './UserManagementTable';
import { AddUserModal } from '../user-manager/AddUserModal';
import { ReloadOutlined, UserAddOutlined } from '@ant-design/icons';
import './ClientUserManager.scss';
import { escapeRegExp } from 'lodash-es';
import { fetchClientUserRoles } from 'api/client';
import { getEmailDomainsForAccount } from 'api/accountSettings';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Search } = Input;

type UserManagerProps = {
  siteAdmin: boolean | undefined;
  fetchUsers: () => void;
  emailDomains?: Array<string>;
  userRoles?: Role[];
  disableAddUser?: boolean;
  isFederatedSignon?: boolean;
};

/**
 * ClientUserManager is a React functional component responsible for managing users, roles, and email domains in a client-facing application.
 *
 * It provides functionalities like user creation, role management, viewing active and inactive users, user search,
 * sending password reset emails, and deleting users. The component also enables toggling the visibility of blocked users and allows site administrators
 * to manage user data effectively.
 *
 * This component interacts with both local state and global state through Recoil for maintaining and updating user-related data,
 * ensuring seamless integration with the overall application state.
 *
 * @component
 * @param {UserManagerProps} props - The props to initialize the ClientUserManager component.
 * @param {boolean} props.siteAdmin - Indicates if the current user has site administrator privileges.
 * @param {Function} props.fetchUsers - Function to fetch the list of users.
 * @param {Array} props.emailDomains - Preloaded email domains for accounts.
 * @param {Array} props.userRoles - Preloaded roles that can be assigned to users.
 * @param {boolean} props.disableAddUser - Indicates if the "Add User" button should be disabled.
 * @param {boolean} props.isFederatedSignon - Specifies if the account uses federated single sign-on.
 *
 * @returns {React.ReactElement} A React component for user and role management.
 */
export const ClientUserManager: FC<UserManagerProps> = ({
  siteAdmin,
  fetchUsers,
  emailDomains: loadedEmailDomains,
  userRoles: loadedUserRoles,
  disableAddUser,
  isFederatedSignon
}: UserManagerProps): React.ReactElement => {
  // LOCAL STATE
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [emailDomains, setEmailDomains] = useState<any[]>(loadedEmailDomains || []);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [showBlockedUsers, setShowBlockedUsers] = useState<boolean>(false);

  // RECOIL STATE
  const contractEntitlements = useRecoilValue(contractEntitlementsState);
  const loadingUsers = useRecoilValue(loadingUsersState);
  const allUsers = useRecoilValue(usersState);
  const [users, setUsers] = useState<Auth0User[]>([]);
  const [selectedUser, setSelectedUser] = useRecoilState(selectedAuth0UserState);
  const [userContractEntitlements, setUserContractEntitlements] = useRecoilState(userContractEntitlementsState);
  const creatable = siteAdmin ? ACLCheck(['create:all_companies']) : true;
  const [searchValue, setSearchValue] = useState<string>('');
  const activeUsers = users?.filter((user) => !user.blocked).length;
  const isAccountFederatedSignon = useRecoilValue(isAccountFederatedSignonState);

  useEffect(() => {
    allUsers && setUsers(allUsers);
  }, [allUsers]);

  useEffect(() => {
    (async () => {
      setLoadingRoles(true);
      const clientUserRoles = siteAdmin && loadedUserRoles ? loadedUserRoles : await fetchClientUserRoles();
      setUserRoles(clientUserRoles || []);
      const clientEmailDomains =
        siteAdmin && loadedEmailDomains ? loadedEmailDomains : await getEmailDomainsForAccount();
      setEmailDomains(clientEmailDomains || []);
      setLoadingRoles(false);
    })();
  }, [siteAdmin, loadedUserRoles, loadedEmailDomains]);

  useEffect(() => {
    if (searchValue?.length > 0) {
      const regExp = new RegExp(escapeRegExp(searchValue), 'i');

      setUsers(
        allUsers?.filter(
          (user) => user.full_name?.match(regExp) || user.given_name?.match(regExp) || user.family_name?.match(regExp)
        )
      );
    } else {
      setUsers(allUsers);
    }
  }, [searchValue, allUsers]);

  const handleAddUser = async () => {
    if (emailDomains?.length > 0) {
      setSelectedUser(undefined);
      setShowAddUserModal(true);
    } else {
      message.warning('Please create Email domains before adding the users');
    }
  };

  const handleEditUser = async (user: Auth0User) => {
    setSelectedUser(user);
    setShowAddUserModal(true);
  };

  const handleSendResetEmail = async (user: Auth0User) => {
    const results = await sendUserPasswordReset(user.email);

    if (results?.status === 'success') {
      message.success(`We have just send a reset password email to ${user.email}`);
    }
  };

  const handleFinish = async (res: Auth0User) => {
    setShowAddUserModal(false);
    // const sfAccountId = selectedClient?.key;
    // if (!isString(sfAccountId)) return;

    if (selectedUser) {
      // setUsers((prev) => prev.map((item) => (item.user_id === res.user_id ? res : item)));
      message.success(`Successfully Updated the User`);
      fetchUsers();
    } else {
      if (!res) {
        // notification.error({
        //   description: 'There was a problem adding a user. Please contact support.',
        //   message: 'Account Creation Problem',
        //   duration: 8
        // });
      } else {
        // setRoles(res);
        // if (userContractEntitlements && !res.blocked) {
        //   setUserContractEntitlements([...userContractEntitlements, { auth0_user_id: res.user_id }]);
        // }
        // setUsers((prev) => [...prev, res]);
        message.success(`Successfully Added the User`);
        fetchUsers();
      }
    }

    /**
     * Get auth0 user ids who have been granted an online entitlement
     */
    // selectAccountAllocatedSeats(sfAccountId).then(setUserContractEntitlements);
  };

  // const setRoles = (vals: Auth0User) => {
  //   return userRoles.filter((item) => vals.role_ids.indexOf(item.id) > -1).map((role) => ({ role }));
  // };

  const handleDeleteUser = async (user: Auth0User) => {
    const results = await deleteUser(user.auth0_user_id);

    if (results.status === 'success') {
      message.success('User deleted!');
      fetchUsers();

      // Remove selected user from userContractEntitlements state
      if (userContractEntitlements) {
        setUserContractEntitlements((prev) => prev?.filter((item) => item.auth0_user_id !== user.auth0_user_id));
      }
    }
  };

  /**
   * Toggles the visibility of inactive or blocked users in the UI based on the input checkbox state.
   *
   * @param {CheckboxChangeEvent} e - The event triggered by the checkbox element,
   * containing the updated 'checked' state to determine whether to show inactive users.
   */
  const handleShowInactiveUsers = (e: CheckboxChangeEvent): void => {
    setShowBlockedUsers(e.target.checked);
  };

  const renderUserManagementTable = () => {
    return (
      <>
        <Row justify="space-between">
          <Col span={6}>
            <Search
              placeholder="Search User"
              allowClear
              enterButton={false}
              size="large"
              className="mb-5"
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Col>
          <Col>
            <span className="font-semibold text-black">Active Users: </span>
            {activeUsers}
            {siteAdmin && creatable ? (
              <Button
                type="link"
                size="middle"
                icon={<UserAddOutlined />}
                onClick={handleAddUser}
                disabled={loadingRoles || loadingUsers || disableAddUser || isFederatedSignon}
              >
                Add User
              </Button>
            ) : null}
            <Button type="link" size="middle" icon={<ReloadOutlined />} onClick={fetchUsers}>
              Refresh
            </Button>
          </Col>
        </Row>
        <UserManagementTable
          title={() => <Checkbox onChange={handleShowInactiveUsers}>Include Inactive Users</Checkbox>}
          className="users-table sm-font"
          loading={loadingUsers}
          dataSource={users.filter((user) => (!showBlockedUsers ? !user.blocked : true))}
          editable={true} //{siteAdmin ? ACLCheck(['update:all_company_users']) : ACLCheck(['update:own_company_user'])}
          deletable={!!(siteAdmin && ACLCheck(['delete:all_company_users']))}
          onEditUser={handleEditUser}
          onSendResetEmail={handleSendResetEmail}
          onDeleteUser={handleDeleteUser}
          isFederatedSignon={isFederatedSignon}
          showSorterTooltip={false}
        />
        <AddUserModal
          open={showAddUserModal}
          onCancel={() => setShowAddUserModal(false)}
          onSubmit={handleFinish}
          roles={userRoles}
          initialValues={selectedUser}
          siteAdmin={siteAdmin}
          user={selectedUser}
          availableSeats={+contractEntitlements - (userContractEntitlements?.length || 0)}
          emailDomains={emailDomains}
          isFederatedSignon={isFederatedSignon}
        />
      </>
    );
  };

  return siteAdmin ? (
    renderUserManagementTable()
  ) : (
    <Card
      title={
        <>
          <h2>User Management</h2>
        </>
      }
      extra={
        creatable ? (
          <Button
            type="primary"
            size="large"
            icon={<UserAddOutlined />}
            onClick={handleAddUser}
            disabled={loadingRoles || loadingUsers || isFederatedSignon || isAccountFederatedSignon}
          >
            Add User
          </Button>
        ) : null
      }
      // headStyle={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
    >
      <p>Manage your team&apos;s users and their site permissions, and initiate a password reset.</p>
      {renderUserManagementTable()}
    </Card>
  );
};
