import React, { FC, useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { clientAdminTabKeyState, loadingUsersState, usersState } from 'recoil/atoms';
import { getSelectedClientUsers } from 'api/admin';
import { ClientUserManager } from './ClientUserManager';
import { Skeleton } from 'antd';
import { Role } from '../../types/global';

interface UserManagementProps {
  siteAdmin?: boolean;
  emailDomains?: Array<string>;
  fetchSelectedClientUsers?: () => void;
  userRoles?: Role[];
  disableAddUser?: boolean;
  isFederatedSignon?: boolean;
}

export const UserManagement: FC<UserManagementProps> = ({
  siteAdmin,
  fetchSelectedClientUsers,
  emailDomains,
  userRoles,
  disableAddUser,
  isFederatedSignon
}: UserManagementProps) => {
  // RECOIL STATE
  const clientAdminTabKey = useRecoilValue(clientAdminTabKeyState);
  const setLoadingUsers = useSetRecoilState(loadingUsersState);
  const loadingUsers = useRecoilValue(loadingUsersState);
  const setUsers = useSetRecoilState(usersState);

  const fetchUsers = useCallback(async () => {
    if (clientAdminTabKey === 'user_admin' && !siteAdmin) {
      setLoadingUsers(true);
      const res = await getSelectedClientUsers();
      if (Array.isArray(res?.data)) setUsers(res?.data);
      setLoadingUsers(false);
    }
  }, [clientAdminTabKey, setLoadingUsers, setUsers, siteAdmin]);

  useEffect(() => {
    (async () => {
      await fetchUsers();
    })();
  }, [clientAdminTabKey, fetchUsers, siteAdmin]);

  return (
    <Skeleton loading={loadingUsers} active>
      <ClientUserManager
        siteAdmin={siteAdmin}
        fetchUsers={siteAdmin && fetchSelectedClientUsers ? fetchSelectedClientUsers : fetchUsers}
        emailDomains={emailDomains}
        userRoles={userRoles}
        disableAddUser={disableAddUser}
        isFederatedSignon={isFederatedSignon}
      />
    </Skeleton>
  );
};
