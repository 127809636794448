import {Button, Card, Form, Input, Skeleton, Spin} from 'antd';
// import { fetchAi } from '../api/ai';
// import axios from 'axios';
import { useEffect, useState } from 'react';
import { Storage } from 'shared/storage';
import { BASE_URL } from '../shared/constants';

const KnowledgeGraphTest: React.FC = () => {
  const [form] = Form.useForm();
  const [answer, setAnswer] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setAnswer('');
  }, []);

  const token = Storage.getToken();

  const onFinish = async (values: { question: string | undefined }) => {
    setAnswer('');
    try {
      // const response = await fetchAi(values);
      setLoading(true);
      const response = await fetch(`${BASE_URL}/secure/stream/kg/ask`, {
        // responseType: 'stream',
        headers: {
          CacheControl: 'no-cache',
          Accept: 'application/json,text/plain',
          Authorization: `Bearer ${token}`
        }
      });
      if (!response || !response.body) return;
      const reader = response.body.getReader();
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Process the chunk of data (e.g., parse JSON)
        const chunk = new TextDecoder().decode(value);
        setAnswer((prevState) => prevState + chunk);
      }

      // if (response) {
      //   const stream = response.data;
      //
      //   stream.on('data', (data: any) => {
      //     console.log(data);
      //   });
      //
      //   stream.on('end', () => {
      //     console.log('stream done');
      //   });
      // }
    } catch (e) {
      console.log(e);
    } finally {
      console.log('Finished');
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Knowledge Graph Chat</h1>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="question"
          label="Question"
          rules={[{ required: true, message: 'Please input your question!' }]}
        >
          <Input.TextArea rows={3} placeholder="Type your question here..." style={{ width: '100%' }} />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {loading ? 'Retrieving answer' : 'Submit'}
        </Button>
      </Form>

      <div className="my-6">
        <Card title={'Answer'} style={{ width: '100%' }}>
          <Spin spinning={loading}>
            {answer}
          </Spin>
        </Card>
      </div>
      <div className="text-xs">
        What is a typical cost increase as a percentage when renewing a ServiceNow contract?
      </div>
    </div>
  );
};

export default KnowledgeGraphTest;
