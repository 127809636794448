import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Divider, InputNumber, message, Popconfirm, Row, Tag, Typography } from 'antd';
import { SupplierDynamicScore } from 'models/SupplierDynamics';
import 'components/supplier-dynamics/SupplierCard.scss';
import './DealDynamics.scss';
import { useRecoilValue } from 'recoil';
import { selectedSupplierDynamicsVendorState } from 'recoil/atoms';
import {
  addCustomRunway,
  deleteCustomRunway,
  fetchCustomRunway,
  fetchSDSupplierScores,
  updateCustomRunway
} from 'api/vendor';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const panelMarginBottom = 24;

/**
 * Represents a component that displays deal dynamics.
 *
 * @component
 * @returns {React.ReactElement} A React element representing the DealDynamics component.
 */
export const DealDyanmics = (): React.ReactElement => {
  const [supplierScores, setSupplierScores] = useState<SupplierDynamicScore[]>();
  const [activeKey, setActiveKey] = useState([1]);
  const [showCustomRunway, setShowCustomRunway] = useState(false);
  const [customRunwayValue, setCustomRunwayValue] = useState<number | null>(3);
  const [savedCustomRunwayValue, setSavedCustomRunwayValue] = useState<number | null>(null);

  // RECOIL STATE
  const selectedSupplier = useRecoilValue(selectedSupplierDynamicsVendorState);

  const scroresHint: any = {
    1: 'LOW',
    2: 'MED',
    3: 'HIGH'
  };

  useEffect(() => {
    const activeKey = localStorage.getItem('supplierDynamicsCollapseKey');
    if (activeKey) {
      setActiveKey(JSON.parse(activeKey));
    }
  }, []);

  useEffect(() => {
    if (!selectedSupplier) return;

    (async () => {
      const scores = await fetchSDSupplierScores(selectedSupplier.id);
      setSupplierScores(scores);
      const customRunway = await fetchCustomRunway(selectedSupplier.id);
      if (customRunway) {
        setSavedCustomRunwayValue(customRunway.runway);
        setShowCustomRunway(true);
      } else {
        setSavedCustomRunwayValue(null);
        setShowCustomRunway(false);
      }
    })();
  }, [selectedSupplier]);

  const handleSaveCustomRunway = async (value: number, isEdit?: boolean) => {
    if (value && selectedSupplier) {
      setSavedCustomRunwayValue(value);
      const res = isEdit
        ? await updateCustomRunway(selectedSupplier.id, value)
        : await addCustomRunway(selectedSupplier.id, value);
      if (res.status === 'success') {
        setShowCustomRunway(true);
        message.success('Custom runway saved successfully');
      } else {
        message.error('Failed to save custom runway');
      }
    }
  };

  const handleDeleteCustomRunway = async () => {
    if (selectedSupplier) {
      const res = await deleteCustomRunway(selectedSupplier.id);
      if (res.status === 'success') {
        setShowCustomRunway(false);
        setCustomRunwayValue(3);
        message.success('Custom runway deleted successfully');
      } else {
        message.error('Failed to delete custom runway');
      }
    }
  };

  /**
   * Calculates the class for the needle based on the given value.
   *
   * @param {number} value - The value to calculate the needle class for.
   * @returns {string} The class for the needle.
   */
  const getNeedleClass = (value: number): string => {
    switch (value) {
      case 1:
        return 'rotate(-82deg)';
      case 3:
        return 'rotate(82deg)';
      default:
        return '';
    }
  };

  /**
   * Returns the transformation string needed for rotating a needle based on the score percentage.
   *
   * @param {SupplierDynamicScore} score - The dynamic score of the supplier.
   * @return {string} - The transformation string for rotating the needle.
   */
  const getPercentNeedleTransform = (score: SupplierDynamicScore): string => {
    return `rotate(${score.value * 600}deg)`;
  };

  /**
   * Retrieves the renewal negotiation value.
   *
   * @returns {number} The renewal negotiation value.
   */
  const getRenewalNegotiationValue = (): number => {
    return supplierScores?.find((score) => score.deal_dynamic.key === 'negotiationRunway')?.value || 3;
  };

  /**
   * Renders the negotiation runway element.
   *
   * @returns {React.ReactElement} The rendered negotiation runway element.
   */
  const negotiationRunwayElement = (isCustom?: boolean): React.ReactElement => {
    return (
      <>
        <div className="flex text-xl font-bold ff-system place-content-between">
          <div>{isCustom ? savedCustomRunwayValue : getRenewalNegotiationValue()} months</div>
          {isCustom && (
            <div>
              <Popconfirm
                title={
                  <>
                    <div className="font-semibold mb-2">Edit Custom Negotiation Runway</div>
                    {renderCustomNegotiationRunwayPrompt(savedCustomRunwayValue || 3)}
                  </>
                }
                onConfirm={() => handleSaveCustomRunway(customRunwayValue || 3, true)}
                okText="Save"
                cancelText=""
                icon={undefined}
                okButtonProps={{
                  size: 'middle',
                  className: 'float-left',
                  disabled: !(customRunwayValue && customRunwayValue > 0 && customRunwayValue <= 24)
                }}
                destroyTooltipOnHide={true}
                onOpenChange={(open) => !open && !customRunwayValue && setCustomRunwayValue(3)}
              >
                <Button size="middle" type="text" icon={<EditOutlined className="text-blue-500" />} />
              </Popconfirm>
              <Button
                size="middle"
                type="text"
                icon={<DeleteOutlined className="text-red-500" onClick={() => handleDeleteCustomRunway()} />}
              />
            </div>
          )}
        </div>
        {isCustom
          ? renderLinerScale(savedCustomRunwayValue || 3, 1, 24)
          : LinearScale(supplierScores?.find((score) => score.deal_dynamic.key === 'negotiationRunway'))}
      </>
    );
  };

  const renderLinerScale = (value: number, scale_low: number, scale_high: number) => {
    return (
      <div className={'linear-scale-container'}>
        {(() => {
          const td = [];
          for (let i = scale_low; i <= scale_high; i++) {
            td.push(<Tag key={i} color={i < value + scale_low ? '#ff6961' : '#ececec'} className={`linear-tag`} />);
          }
          return td;
        })()}
      </div>
    );
  };

  /**
   * Renders a linear scale component based on the given dynamic score.
   *
   * @param {SupplierDynamicScore | undefined} sds - The dynamic score object or undefined if not available.
   * @returns {React.ReactElement} - The rendered linear scale component.
   */
  const LinearScale = (sds: SupplierDynamicScore | undefined): React.ReactElement => {
    /**
     * sd represents either provided supplier dynamic score or default value if
     * score doesn't exist in reponse.
     */
    const sd = sds || {
      value: 3,
      deal_dynamic: {
        scale_low: 1,
        scale_high: 12
      }
    };
    return renderLinerScale(sd.value, sd.deal_dynamic.scale_low, sd.deal_dynamic.scale_high);
  };

  /**
   * Toggles the open state of the component, activating or deactivating the key.
   *
   * @function handleToggleOpen
   * @returns {void}
   */
  const handleToggleOpen = (): void => {
    const _activeKey = activeKey.length > 0 ? [] : [1];
    localStorage.setItem('supplierDynamicsCollapseKey', JSON.stringify(_activeKey));
    setActiveKey(_activeKey);
  };

  const renderCustomNegotiationRunwayPrompt = (defaultCustomRunwayValue?: number) => {
    return (
      <>
        <div className="flex mb-2">
          <InputNumber
            className="center"
            defaultValue={defaultCustomRunwayValue || 3}
            min={1}
            max={24}
            onChange={(value) => {
              setCustomRunwayValue(value);
            }}
            precision={0}
          />
          <div className="content-top m-2">Months</div>
        </div>
      </>
    );
  };

  return (
    <div className="deal-dynamics">
      <div style={{ marginBottom: panelMarginBottom }}>
        <Collapse
          ghost
          expandIconPosition="end"
          expandIcon={() =>
            activeKey.length === 0 ? (
              <div style={{ fontSize: 13 }}>Show description</div>
            ) : (
              <div style={{ fontSize: 13 }}>Hide description</div>
            )
          }
          onChange={handleToggleOpen}
          activeKey={activeKey}
          className="mb-5"
        >
          <Panel header={'Purchase Characteristics'} key={1} showArrow={true}>
            <div className="-mt-1">
              These scores, gleaned from NPI&apos;s extensive market data, serve as a critical resource for IT sourcing
              professionals navigating the complex vendor landscape. Leveraging advanced analytics and historical
              trends, they offer a nuanced understanding of vendor behavior, pricing flexibility, and deal size trends.
              Using these scores, you can identify vendors whose offerings, pricing models, and market dynamics best fit
              your procurement strategies, ultimately leading to more favorable terms, cost savings, and successful
              vendor relationships.
            </div>
          </Panel>
        </Collapse>

        {supplierScores
          ?.filter((score) => score.deal_dynamic.key !== 'negotiationRunway')
          .map((score: SupplierDynamicScore, key: number) => (
            <Row key={key} className="mb-6">
              <Col flex={'200px'}>
                <div
                  className={`gauge-wrapper ${
                    score.deal_dynamic.chart_type === 'percent' ? 'gauge-percentage' : 'gauge-high-low'
                  }`}
                >
                  <div
                    className="needle"
                    style={{
                      transform:
                        score.deal_dynamic.chart_type === 'percent'
                          ? `${getPercentNeedleTransform(score)}`
                          : `${getNeedleClass(score.value)}`
                    }}
                  ></div>
                  <div className="score">
                    {score.deal_dynamic.chart_type === 'percent'
                      ? `${Math.round(score.value * 100)}%`
                      : scroresHint[score.value]}
                  </div>
                </div>
              </Col>
              <Col
                className="pr-4"
                span={key > 0 ? 19 : 14}
                style={{ borderRight: key === 0 ? '1px solid #ccc' : 'none' }}
              >
                <div className="deal-dynamic">
                  <span className="name">{score.deal_dynamic.name}</span>:{' '}
                  <span className="score-hint">
                    {score.deal_dynamic.chart_type === 'lowMediumHigh'
                      ? score.deal_dynamic.score_hints[score.value - 1]
                      : score.deal_dynamic.chart_type === 'percent'
                      ? score.deal_dynamic.score_hints[
                          score.value < 0 ? 0 : score.value === 0 ? 1 : score.value > 0 ? 2 : 1
                        ]
                      : ''}
                  </span>
                </div>
                <div className="score-tooltips">
                  {score.deal_dynamic.chart_type === 'lowMediumHigh'
                    ? score.deal_dynamic.score_tooltips[score.value - 1]
                    : score.deal_dynamic.chart_type === 'percent'
                    ? score.deal_dynamic.score_tooltips[
                        score.value < 0 ? 0 : score.value === 0 ? 1 : score.value > 0 ? 2 : 1
                      ]
                    : ''}
                </div>
              </Col>
              {key === 0 && (
                <Col span={5}>
                  <Card size="small" className="bg-gray-100 p-0 m-2 mb-0">
                    <Typography.Text>Negotiation Runway</Typography.Text>
                    <div>{negotiationRunwayElement(false)}</div>
                    <>
                      <Divider className="m-0" />
                      <Popconfirm
                        title={
                          <>
                            <div className="font-semibold mb-2">Add Custom Negotiation Runway</div>
                            {renderCustomNegotiationRunwayPrompt(3)}
                          </>
                        }
                        onConfirm={() => handleSaveCustomRunway(customRunwayValue || 3)}
                        okText="Save"
                        cancelText=""
                        icon={undefined}
                        okButtonProps={{
                          size: 'middle',
                          className: 'float-left',
                          disabled: !(customRunwayValue && customRunwayValue > 0 && customRunwayValue <= 24)
                        }}
                        destroyTooltipOnHide={true}
                        onOpenChange={(open) => !open && !customRunwayValue && setCustomRunwayValue(3)}
                      >
                        {!showCustomRunway && (
                          <Button className="p-0" type="link">
                            Add Custom Runway
                          </Button>
                        )}
                      </Popconfirm>
                      {showCustomRunway && (
                        <div className="mt-2">
                          <Typography.Text>Custom Runway</Typography.Text>
                          <div>{negotiationRunwayElement(true)}</div>
                        </div>
                      )}
                    </>
                  </Card>
                </Col>
              )}
            </Row>
          ))}
      </div>
    </div>
  );
};
