import { AssignmentModel, AssignmentModelConfig } from '@bryntum/gantt';

export interface ClientRenewals {
  success: boolean;
  project: Project;
  resources: Resource[];
  tasks: Task[];
  assignments: AssignmentModel[] | Partial<AssignmentModelConfig>[];
}

export interface SupplierRenewals {
  success: boolean;
  project: Project;
  tasks: Task[];
}

export interface Project {
  calendar: string;
  startDate: Date;
  endDate: Date;
  type?: string;
}

export interface ClientContact {
  Id: string;
  Name: string;
}

export interface RenewalRecord {
  id: number;
  name: string;
  expanded: boolean;
  readOnly: boolean;
  recordType?: string;
  children: RenewalChildRecord[];
  contractsCount?: number;
}

export interface RenewalChildRecord extends RenewalRecord {
  clientContact: ClientContact;
  runway?: number;
  remaining: number;
  startDate: Date;
  endDate: Date;
  status: string;
}

export interface Task extends RenewalRecord {
  orgName?: string;
  _data: GanttTask;
  originalData: GanttTask;
  isLeaf: boolean;
  children: RenewalChildRecord[];
  default_deal_tasks: Array<RenewalChildRecord> | null;
}

export interface TaskChild extends RenewalChildRecord {
  note?: string;
  subject: string;
  children: RenewalChildRecord[];
  notification_queue?: any[];
  manuallyScheduled: boolean;
  readOnly: boolean;
}

export interface ChildChild extends RenewalChildRecord {
  rollup: boolean;
  tcv: number;
  contractsCount?: number;
  manuallyScheduled: boolean;
  percentDone: number;
  remaining: number;
  duration: number;
  subject: string;
  is_proposal: boolean;
  sd?: SD[];
  priceVariability?: number;
  optimizationImpact?: number;
  renewalNegotiability?: number;
  runway?: number;
  children: ChildChild[];
  fiscal_year_end?: string;
  notification_queue?: any[];
}

export interface SD {
  id: number;
  name: string;
  mean: number;
}

export interface GanttTask {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  recordType?: string;
  rollup: boolean;
  tcv: number;
  contractsCount?: number;
  manuallyScheduled: boolean;
  percentDone: number;
  remaining: string;
  duration: number;
  subject?: string;
  is_proposal: boolean;
  parentId: number;
  durationUnit: string;
  cls: string;
  direction: string;
  unscheduled: boolean;
  slackUnit: string;
  critical: boolean;
  effortUnit: string;
  effortDriven: boolean;
  schedulingMode: string;
  draggable: boolean;
  resizable: boolean;
  showSd: boolean;
  wbsValue: WbsValue;
  parentIndex: number;
  effort: number;
  segments: null;
  constraintType: null;
  constraintDate: null;
  earlyStartDate: Date;
  earlyEndDate: Date;
  orderedParentIndex: number;
  calendar: null;
  sd?: SD[];
  runway?: number;
  orgName?: string;
  fiscal_year_end?: string;
  default_deal_tasks: Array<ChildChild> | null;
  status: string | null;
}

export interface WbsValue {
  _value: string;
  _padded: null;
}

export interface Resource {
  id: string;
  name: string;
}

export interface RenewalNotification {
  id: number;
  salesforce_deal_name: string;
  subject: string;
  contract_start_date: Date;
  contract_renewal_date: Date;
  vendor: Vendor;
  notification_queue: NotificationQueue[];
  tcv: number;
}

export interface NotificationQueue {
  id: number;
  job_id: number;
  request: Request;
}

export enum RequestZone {
  OrangeZone = 'orange',
  RedZone = 'red',
  RenewalDate = 'renewalDate'
}

export interface Request {
  name: string;
  zone: RequestZone;
  dateTime: Date;
  daysBefore: number;
  contract_id: number;
}

export interface Vendor {
  id: number;
  name: string;
  logo_filename: string;
  signedUrl: string;
  renewalRunway: number;
}
