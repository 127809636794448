import {
  ColumnStore,
  ColumnStoreConfig,
  GanttConfig,
  GridColumnConfig,
  ProjectModelConfig,
  TaskModel
} from '@bryntum/gantt';
import {
  ANONYMOUS_CLIENT_NAME,
  DEAL_SIZE_TREND_CHARACTERISTICS,
  DYNAMIC_COLUMNS,
  PURCHASE_CHARACTERISTICS_VALUE,
  PURCHASE_CHARACTERISTICS_THREE_COLORS,
  RENEWAL_RECORD_TYPE,
  PURCHASE_CHARACTERISTICS_EIGHT_COLORS
} from 'shared/constants';

import { Avatar, Badge, Button, Col, List, Popover, Row, Space, Tooltip } from 'antd';
import { FieldSorter, FormatCurrency } from 'shared/Helpers';
import { RenewalAlertsModal } from 'components/renewals/RenewalAlertsModal';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { profileSwitchState } from 'recoil/atoms';
import React from 'react';
import {
  AppstoreAddOutlined,
  BankFilled,
  BellFilled,
  BellOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  StarFilled
} from '@ant-design/icons';
import { useDealHelper } from 'hooks/useDealHelper';
import { Link } from 'react-router-dom';
import SupplierDynamicsPopover from '../../atoms/SupplierDynamicsPopover';
import { GanttChartMode } from 'sb/shared/constants';
import { taskRenderer } from 'shared/BryntumGantt';

class MyTaskModel extends TaskModel {
  static get fields() {
    return [
      { name: 'tcv', type: 'number' },
      { name: 'renewal', type: 'string' },
      { name: 'priceVariability', type: 'number' },
      { name: 'optimizationImpact', type: 'number' },
      { name: 'renewalNegotiability', type: 'number' },
      { name: 'runway', type: 'number' },
      { name: 'fiscal_year_end' },
      { name: 'status', type: 'string' }
    ];
  }
}

const ganttChartColumnConfig = {
  [GanttChartMode.OWN_CALENDER]: [
    'name',
    'tcv',
    'endDate',
    'fmvReportLink',
    'primaryContact',
    'status',
    'alert',
    'fiscal_year_end',
    'vendorComplexity',
    'pricingFlexibility',
    'dealSizeTrend'
  ],
  [GanttChartMode.PORTFOLIO]: [
    'name',
    'tcv',
    'endDate',
    'fiscal_year_end',
    'vendorComplexity',
    'pricingFlexibility',
    'dealSizeTrend'
  ]
};
const { ROOT, SUPPLIER, SUPPLIER_DEAL, DEAL, TASK } = RENEWAL_RECORD_TYPE;

const useGanttConfig = (mode: GanttChartMode, taskClickHandler: (record: any) => void): Partial<GanttConfig> => {
  const profileSwitch = useRecoilValue(profileSwitchState);
  const { dealIdToAddTasks, handleAddTasksToDeal, categoriesData, handleEditDealTask } = useDealHelper();
  const { data: taskCategories, isLoading: isCategoriesLoading } = categoriesData;

  /**
   * Edit a task in a deal
   *
   * @param {number} dealId - The ID of the deal
   * @param {number} taskId - The ID of the task to be edited
   * @param {object} data - The data for the edited task
   * @param {string} data.name - The name of the task
   * @param {string} data.note - The note for the task
   * @param {array} data.assignment - The assignments for the task
   * @param {string} data.startDate - The start date of the task (formatted as 'MM/DD/YYYY')
   * @param {string} data.endDate - The end date of the task (formatted as 'MM/DD/YYYY')
   *
   * @returns {void}
   */
  const handleEditTask = (dealId: number, taskId: number, data: any): void => {
    const task = {
      name: data.name,
      note: data.note,
      assignments:
        data?.assignment?.map((a: any) => {
          return a.users.auth0_user_id;
        }) || [],
      startDate: moment(data.startDate).format('MM/DD/YYYY'),
      endDate: moment(data.endDate).format('MM/DD/YYYY')
    };
    handleEditDealTask(dealId, taskId, task);
  };

  /**
   * Renders a list of task categories for a given dealId.
   *
   * @param dealId - The ID of the deal.
   * @returns A React element representing the list of task categories.
   */
  const renderCategories = (dealId: number): React.ReactElement => {
    return (
      <>
        <Row className="font-medium mb-2 title-intro">Add NPI recommended tasks or make your own list</Row>
        {taskCategories && (
          <List
            size="small"
            dataSource={[
              ...taskCategories,
              {
                categoryId: '0',
                categoryName: 'Custom'
              }
            ]}
            renderItem={(item: any) => (
              <List.Item
                className="cursor-pointer"
                onClick={() => handleAddTasksToDeal(dealId, item.categoryId as number)}
              >
                {item.categoryName}
              </List.Item>
            )}
          />
        )}
      </>
    );
  };

  /**
   * Function to render a popover component with the option to add tasks.
   *
   * @param {number} dealId - The deal ID to render categories for
   * @returns {React.ReactElement} - The rendered popover component
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderPopover = (dealId: number): React.ReactElement => (
    <>
      {dealIdToAddTasks?.dealId || isCategoriesLoading ? (
        <></>
      ) : (
        <Popover
          className="add-task-icon mr-2"
          title={<h3 className="font-bold pt-2 mb-0">Add Tasks</h3>}
          content={renderCategories(dealId)}
          trigger={'hover'}
          placement="right"
          zIndex={999}
          overlayStyle={{
            width: '300px'
          }}
          overlayInnerStyle={{
            paddingBottom: '0'
          }}
        >
          <div className="rounded-full bg-blue-500 flex items-center justify-center w-6 h-6">
            <AppstoreAddOutlined className="cursor-pointer" style={{ color: 'white' }} />
          </div>
        </Popover>
      )}
    </>
  );

  /**
   * Renders a tooltip for client contact with a title and a list of names.
   *
   * @param {string} title - The title of the tooltip.
   * @param {Array<string>} names - An array of names to be displayed in the tooltip.
   * @returns {React.ReactElement} - The rendered tooltip component.
   */
  const renderToolTipForClientContact = (title: string, names: Array<string>): React.ReactElement => (
    <div style={{ textAlign: 'center' }}>
      <span className="deal-owner-text">
        {title}
        <hr className="hr-no-space" />
      </span>
      {names.map((name: string, index: number) => (
        <span className="deal-owner-names" key={index}>
          {name}
          <br />
        </span>
      ))}
    </div>
  );

  /**
   * Function to render client contact avatars.
   *
   * @param {string} fullName - The full name of the contact.
   * @param {string} styleClass - The style class to be applied to the avatar.
   * @returns {React.ReactElement} - The avatar component.
   */
  const renderClientContactAvatars = (fullName: string, styleClass: string): React.ReactElement => {
    const [firstNameInitial, lastNameInitial] = fullName.split(' ').map((name) => name.charAt(0));
    const initials = (
      <>
        <div className={`initials ${styleClass}`}>
          {firstNameInitial}
          {lastNameInitial ? lastNameInitial : null}
        </div>
      </>
    );
    return (
      <Avatar size={30} className={styleClass}>
        {initials}
      </Avatar>
    );
  };

  /**
   * Retrieves the color code for the specified deal size trend value.
   *
   * @param {number} value - The deal size trend value.
   * @returns {string} The color code associated with the deal size trend value.
   */
  const getDealSizeTrendColor = (value: number): string => {
    let index;
    if (value <= -20) {
      index = 0;
    } else if (value >= 20) {
      index = 7;
    } else {
      value = value + 20;
      index = Math.floor(value / 5);
    }
    return PURCHASE_CHARACTERISTICS_EIGHT_COLORS[index];
  };

  /**
   * Retrieves the supplier dynamics object from a given record based on the provided property.
   *
   * @param {any} record - The record object from which to retrieve the supplier dynamics.
   * @param {string} prop - The property value to match with the deal_dynamic.key property of each supplier dynamics object.
   *
   * @returns {any} - The matching supplier dynamics object, if found. Otherwise, returns undefined.
   */
  const getSupplierDynamicsObj = (record: any, prop: string): any => {
    const supplierDynamics = record._data.supplierDynamics;
    return supplierDynamics?.find((item: any) => item.deal_dynamic.key === prop);
  };

  const ganttChartColumns: ColumnStore | Partial<GridColumnConfig>[] | Partial<ColumnStoreConfig> = [
    {
      type: 'name',
      field: 'name',
      width: 450,
      // autoWidth: true,
      htmlEncode: false,
      renderer: ({ record, value }: any) => {
        if (record._data.recordType === ROOT) {
          return profileSwitch?.anonymous ? (
            <strong>{ANONYMOUS_CLIENT_NAME}</strong>
          ) : (
            <Space>
              {value}
              <Tooltip title="Count of contracts">
                <Badge count={record._data.contractsCount} showZero={true} overflowCount={99999} />
              </Tooltip>
            </Space>
          );
        } else if (record._data.recordType === SUPPLIER_DEAL) {
          return (
            <>
              <div className="flex items-center deal-row">
                {(record._data.status !== 'Calendar' && (
                  <div className="flex items-center">
                    <FileTextOutlined className="mr-2 opacity-70" />
                    <strong className="flex-1 pr-6 whitespace-normal line-clamp-2">
                      <Link to={`/deal_details/${record._data.id}`}>
                        {record._data.orgName}: {record._data.subject}
                      </Link>
                    </strong>
                  </div>
                )) || (
                  <div className="flex items-center">
                    <Tooltip title="Calendar Deal">
                      <StarFilled className="mr-2 opacity-70" />
                    </Tooltip>
                    <strong className="flex-1 pr-6 whitespace-normal line-clamp-2">
                      <Link to={`/calendar_deals/${record._data.id}`}>
                        {record._data.orgName}: {record._data.subject || '--'}
                      </Link>
                    </strong>
                  </div>
                )}
              </div>
            </>
          );
        } else if (record._data.recordType === SUPPLIER) {
          return (
            <Space size={3}>
              <BankFilled className="opacity-70" /> <strong>{value}</strong>
              <Tooltip title="Count of contracts">
                <Badge count={record.children.length} showZero={true} overflowCount={99999} />
              </Tooltip>
            </Space>
          );
        } else if (record._data.recordType === DEAL) {
          return (
            (
              <div className="flex items-center deal-row">
                {(record._data.status !== 'Calendar' && (
                  <div className="flex items-center">
                    <FileTextOutlined className="mr-2 opacity-70" />
                    <strong className="flex-1 pr-6 whitespace-normal line-clamp-2">
                      <Link to={`/deal_details/${record._data.id}`}>{record._data.subject || record._data.name}</Link>
                    </strong>
                  </div>
                )) || (
                  <div className="flex items-center">
                    <Tooltip title="Calendar Deal">
                      <StarFilled className="mr-2 opacity-70" />
                    </Tooltip>
                    <strong className="flex-1 pr-6 whitespace-normal line-clamp-2">
                      <Link to={`/calendar_deals/${record._data.id}`}>{record._data.subject || '--'}</Link>
                    </strong>
                  </div>
                )}
              </div>
            ) || <span className="italic">Untitled</span>
          );
        } else {
          return (
            <Row align="middle" className="items-center">
              <Col span={22} className="whitespace-normal pr-3 flex-1 line-clamp-2">
                {value}
              </Col>
              {mode == GanttChartMode.OWN_CALENDER ? (
                <Button
                  type="link"
                  size="small"
                  className="add-task-icon bg-blue-500 flex items-center justify-center w-6 h-6 rounded-s-sm hover:bg-blue-400"
                  icon={<EditOutlined style={{ color: 'white' }} />}
                  onClick={() => {
                    handleEditTask(record._data?.parentId, record?._data.id, record._data);
                  }}
                ></Button>
              ) : null}
            </Row>
          );
        }
      }
    },
    {
      type: 'aggregate',
      text: 'TCV<br><span style="font-size:0.8em">(aggregated)</span>',
      field: 'tcv',
      width: 110,
      htmlEncode: false,
      htmlEncodeHeaderText: false,
      renderer: ({ record, value }: any) => {
        // console.log('originalData=', record.originalData, 'value=', value);
        // if (record.originalData.tcv !== undefined) {
        //   return record.isLeaf || record._data.default_deal_tasks
        //     ? FormatCurrency(record.originalData.tcv, 0)
        //     : `<b>${FormatCurrency(record.originalData.tcv, 0)}</b>`;
        // } else {
        //   return '';
        // }
        if (value !== undefined) {
          return record.isLeaf || record._data.default_deal_tasks
            ? FormatCurrency(value, 0)
            : `<b>${FormatCurrency(value, 0)}</b>`;
        } else {
          return '';
        }
      }
    },
    {
      text: 'Renewal',
      field: 'endDate',
      align: 'center',
      htmlEncode: false,
      width: 90,
      renderer: ({ record, value }: any) =>
        record.isLeaf && value ? (value && value < 0 ? `<i>Expired</i>` : moment(value).format('l')) : '-'
    },
    {
      text: 'FMV<br>Report',
      field: 'fmvReportLink',
      align: 'center',
      htmlEncode: false,
      htmlEncodeHeaderText: false,
      width: 80,
      renderer: ({ record }: any) => {
        const fmvReportLink = record.get('fmvReportLink');
        return fmvReportLink ? (
          <Tooltip title="Download FMV Report">
            <Link target="_blank" to={fmvReportLink}>
              {<DownloadOutlined style={{ fontSize: 18 }} />}
            </Link>
          </Tooltip>
        ) : (
          '-'
        );
      }
    },
    {
      text: 'Client<br>Contact',
      field: 'primaryContact',
      autoWidth: true,
      align: 'center',
      htmlEncodeHeaderText: false,
      htmlEncode: false,
      minWidth: 80,
      sortable(a: any, b: any) {
        return FieldSorter(a?._data?.clientContact?.Name, b?._data?.clientContact?.Name);
      },
      renderer: ({ record }: any) => {
        if (record._data.recordType === SUPPLIER_DEAL || record._data.recordType === DEAL) {
          if (record._data.clientContact?.Name) {
            const fullName = record._data.clientContact?.Name;
            return (
              <Tooltip title={renderToolTipForClientContact('Deal Owner', [fullName])}>
                {renderClientContactAvatars(fullName, 'custom-avatar')}
              </Tooltip>
            );
          }
        } else if (record._data.recordType === TASK) {
          const names = record._data.assignment?.map((item: any) => item.users.name);
          if (names && names.length > 0) {
            const additionalNamesCount = names.length - 1;
            return (
              <Tooltip title={renderToolTipForClientContact('Task Assignees', names)}>
                <Badge
                  count={additionalNamesCount > 0 ? `+${additionalNamesCount}` : null}
                  offset={[0, 30]}
                  style={{ backgroundColor: '#152d51', borderRadius: '50%', marginTop: '26px', padding: '0px' }}
                >
                  {renderClientContactAvatars(names[0], 'custom-task-avatar')}
                </Badge>
              </Tooltip>
            );
          }
        } else {
          return <></>;
        }
      }
    },
    {
      // headerRenderer() {
      //   return '<div>Submitted<br />for Analysis</div>';
      // },
      text: 'Submitted',
      field: 'status',
      autoWidth: true,
      align: 'center',
      // htmlEncode: false,
      renderer: ({ record, value }: any) =>
        record.isLeaf && value ? (
          value === 'Calendar' ? (
            <CheckCircleOutlined style={{ color: '#ccc', fontSize: 20 }} />
          ) : (
            <CheckCircleOutlined style={{ color: 'green', fontWeight: 500, fontSize: 20 }} />
          )
        ) : (
          '-'
        )
    },
    {
      text: 'Alert',
      field: 'endDate',
      align: 'center',
      width: 60,
      renderer: ({ record, value }: any) =>
        record.isLeaf ? (
          value && moment(value) > moment() ? (
            <RenewalAlertsModal contract_id={record.originalData.id}>
              {(record._data.notification_queue?.length > 0 && <BellFilled style={{ color: 'green' }} />) || (
                <BellOutlined style={{ color: '#aaa' }} />
              )}
            </RenewalAlertsModal>
          ) : (
            '-'
          )
        ) : (
          ''
        )
    },
    {
      text: 'Fiscal<br>Year End',
      field: 'fiscal_year_end',
      autoWidth: true,
      htmlEncode: false,
      align: 'center',
      htmlEncodeHeaderText: false,
      renderer: ({ record }: any) =>
        record._data.fiscal_year_end ? moment(record._data.fiscal_year_end, 'MM-DD', true).format('MMM Do') : '-'
    },
    {
      type: 'number',
      text: 'Vendor<br>Complexity',
      field: 'vendorComplexity',
      align: 'center',
      htmlEncodeHeaderText: false,
      width: 100,
      sortable(a: any, b: any) {
        const obj1 = getSupplierDynamicsObj(a, DYNAMIC_COLUMNS.VENDOR_COMPLEXITY);
        const obj2 = getSupplierDynamicsObj(b, DYNAMIC_COLUMNS.VENDOR_COMPLEXITY);
        return obj1?.value - obj2?.value;
      },
      renderer: ({ record }: any) => {
        if (record._data.recordType === SUPPLIER_DEAL || record._data.recordType === SUPPLIER) {
          const vendorComplexity = getSupplierDynamicsObj(record, DYNAMIC_COLUMNS.VENDOR_COMPLEXITY);
          if (vendorComplexity) {
            const name = vendorComplexity.deal_dynamic.name;
            const value = PURCHASE_CHARACTERISTICS_VALUE[vendorComplexity.value - 1];
            const avatarColor = PURCHASE_CHARACTERISTICS_THREE_COLORS[vendorComplexity.value - 1];
            const avatarValue = value.charAt(0) || '';
            const tooltipContent = vendorComplexity.deal_dynamic.score_tooltips[vendorComplexity.value - 1];
            return (
              <SupplierDynamicsPopover
                name={name}
                avatarColor={avatarColor}
                value={value}
                avatarValue={avatarValue}
                description={tooltipContent}
              ></SupplierDynamicsPopover>
            );
          } else {
            // console.log('Vendor Complexity not found');
          }
        }
      }
    },
    {
      type: 'number',
      text: 'Pricing<br>Flexibility',
      field: 'pricingFlexibility',
      htmlEncodeHeaderText: false,
      align: 'center',
      width: 110,
      sortable(a: any, b: any) {
        const obj1 = getSupplierDynamicsObj(a, DYNAMIC_COLUMNS.PRICING_FLEXIBILITY);
        const obj2 = getSupplierDynamicsObj(b, DYNAMIC_COLUMNS.PRICING_FLEXIBILITY);
        return obj1?.value - obj2?.value;
      },
      renderer: ({ record }: any) => {
        if (record._data.recordType === SUPPLIER_DEAL || record._data.recordType === SUPPLIER) {
          const pricingFlexibility = getSupplierDynamicsObj(record, DYNAMIC_COLUMNS.PRICING_FLEXIBILITY);
          if (pricingFlexibility) {
            const name = pricingFlexibility.deal_dynamic.name;
            const value = PURCHASE_CHARACTERISTICS_VALUE[pricingFlexibility.value - 1];
            const avatarColor = PURCHASE_CHARACTERISTICS_THREE_COLORS[pricingFlexibility.value - 1];
            const avatarValue = value.charAt(0) || '';
            const tooltipContent = pricingFlexibility.deal_dynamic.score_tooltips[pricingFlexibility.value - 1];
            return (
              <SupplierDynamicsPopover
                name={name}
                avatarColor={avatarColor}
                value={value}
                avatarValue={avatarValue}
                description={tooltipContent}
              ></SupplierDynamicsPopover>
            );
          } else {
            // console.log('Pricing Flexibility not found');
          }
        }
      }
    },
    {
      type: 'number',
      text: 'Deal Size<br>Trend',
      field: 'dealSizeTrend',
      htmlEncodeHeaderText: false,
      align: 'center',
      width: 110,
      sortable(a: any, b: any) {
        const obj1 = getSupplierDynamicsObj(a, DYNAMIC_COLUMNS.DEAL_SIZE_TREND);
        const obj2 = getSupplierDynamicsObj(b, DYNAMIC_COLUMNS.DEAL_SIZE_TREND);
        return obj1?.value - obj2?.value;
      },
      renderer: ({ record }: any) => {
        if (record._data.recordType === SUPPLIER_DEAL || record._data.recordType === SUPPLIER) {
          const dealSizeTrend = getSupplierDynamicsObj(record, DYNAMIC_COLUMNS.DEAL_SIZE_TREND);
          if (dealSizeTrend && dealSizeTrend.value !== null) {
            let tooltipIndex;
            let value;
            if (dealSizeTrend.value < 0) {
              tooltipIndex = 0;
              value = DEAL_SIZE_TREND_CHARACTERISTICS.TRENDING_DOWNWARDS_TEXT;
            } else if (dealSizeTrend.value == 0) {
              tooltipIndex = 1;
              value = DEAL_SIZE_TREND_CHARACTERISTICS.STABLE_TEXT;
            } else {
              tooltipIndex = 2;
              value = DEAL_SIZE_TREND_CHARACTERISTICS.TRENDING_UPWARDS_TEXT;
            }
            const tooltipContent = dealSizeTrend.deal_dynamic.score_tooltips[tooltipIndex];
            const percentageValue = `${Math.round(dealSizeTrend.value * 100)}%`;
            const name = dealSizeTrend.deal_dynamic.name;
            const avatarColor = getDealSizeTrendColor(parseFloat(percentageValue));

            return (
              <SupplierDynamicsPopover
                name={name}
                avatarColor={avatarColor}
                value={value}
                avatarValue={percentageValue}
                description={tooltipContent}
                width={50}
              ></SupplierDynamicsPopover>
            );
          } else {
            // console.log('Deal Size Trend not found');
          }
        }
      }
    }
  ];

  return React.useMemo(
    () => {
      if (taskCategories?.length == 0)
        return { columns: [], viewPreset: 'weekAndDayLetter', barMargin: 8, autoHeight: true, readOnly: false };
      else
        return {
          columns: ganttChartColumns.filter(
            (column) => column.field && ganttChartColumnConfig[mode].includes(column.field)
          ),
          viewPreset: 'weekAndDayLetter',
          barMargin: 8,
          autoHeight: true,
          readOnly: false,
          taskRenderer: taskRenderer
        };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taskCategories]
  );
};

const projectConfig: Partial<ProjectModelConfig> = {
  calendar: 'general',
  // startDate: DateHelper.add(DateHelper.clearTime(new Date()), 0, 'day'),
  hoursPerDay: 24,
  daysPerWeek: 5,
  daysPerMonth: 20,
  autoLoad: true,
  taskModelClass: MyTaskModel,
  // This config enables response validation and dumping of found errors to the browser console.
  // It's meant to be used as a development stage helper only so please set it to false for production systems.
  validateResponse: true
};

const customTaskTooltip = ({ taskRecord }: any): any => {
  return (
    <div className="custom-tooltip">
      {taskRecord._data.orgName && <h4 className={'text-gray-500'}>{taskRecord._data.orgName}</h4>}
      <h4 className={'font-bold'}>{taskRecord._data.subject || taskRecord._data.name}</h4>
      <hr className={'border-gray-100'} />
      <Row>
        <Col span={10} className={'label'}>
          Total Value:
        </Col>
        <Col span={14}>{taskRecord._data.tcv ? FormatCurrency(taskRecord._data.tcv) : '--'}</Col>
      </Row>
      <Row>
        <Col span={10} className={'label'}>
          Start Date:
        </Col>
        <Col span={14}>{moment(taskRecord._data.startDate).format('LL') || '--'}</Col>
      </Row>
      <Row>
        <Col span={10} className={'label'}>
          End Date:
        </Col>
        <Col span={14}>{moment(taskRecord._data.endDate).format('LL') || '--'}</Col>
      </Row>
    </div>
  );
};

export { useGanttConfig, projectConfig, customTaskTooltip };
